import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundAudio from "./BackgroundAudio";

const ImageCarousel = () => {
  const [audio] = useState(new Audio("/images/Last Christmas I Gave You My Heart Arab.mp3"));
  const [showClickHint, setShowClickHint] = useState(true);

  const images = [
    "images/ny/1.png",
    "images/ny/2.png", 
    "images/ny/3.png",
    "images/ny/4.png", 
    "images/ny/5.png",
    "images/ny/6.png",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    const playAudio = async () => {
      try {
        audio.loop = true;
        await audio.play();
        setShowClickHint(false);
      } catch (err) {
        console.log('Autoplay blocked. Adding click event listener...');
        
        const handleClick = async () => {
          try {
            await audio.play();
            setShowClickHint(false);
            document.removeEventListener('click', handleClick);
          } catch (err) {
            console.error('Audio playback failed:', err);
          }
        };
        
        document.addEventListener('click', handleClick);
        
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }
    };

    playAudio();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  return (
    <div className="w-full max-w-4xl mx-auto">
      <BackgroundAudio />
      {showClickHint && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xl">
          Click anywhere to start the audio
        </div>
      )}
      <Slider {...settings}>
        {images.map((url, index) => (
          <div key={index} className="flex justify-center items-center p-4">
            <img
              src={url}
              alt={`Slide ${index + 1}`}
              className="rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
