import React, { useEffect, useRef } from "react";

const BackgroundAudio = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    const playAudio = async () => {
      try {
        await audioRef.current.play();
        console.log("Audio autoplayed successfully.");
      } catch (error) {
        console.log("Autoplay blocked. User interaction might be required.");
      }
    };

    playAudio();
  }, []);

  return (
    <audio ref={audioRef} autoPlay loop>
      <source src="/Last Christmas I Gave You My Heart Arab.mp3" type="audio/mp3" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default BackgroundAudio;
