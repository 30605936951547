import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import WhatsAppAnalysis from "./newYear";
import ImageCarousel from "./cares";

const Home = () => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <h1>Welcome to the Analysis Dashboard</h1>
    <p>Select an analysis page from the navigation.</p>
    <Link to="/whatsapp-analysis">
      <button style={styles.button}>Go to WhatsApp Analysis</button>
    </Link>
  </div>
);

function App() {
  return (
    <Router>
      
        <Routes>
          <Route path="/" element={<ImageCarousel />} />
          <Route path="/whatsapp-analysis" element={<WhatsAppAnalysis />} />
    </Routes>
    </Router>
  );
}

const styles = {
  nav: {
    padding: "10px",
    backgroundColor: "#282c34",
    color: "white",
  },
  navList: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  link: {
    color: "white",
    textDecoration: "none",
    marginRight: "15px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    border: "none",
    borderRadius: "5px",
    color: "white",
    cursor: "pointer",
  },
};

export default App;
