import React from "react";
import Slider from "react-slick";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const WhatsAppAnalysis = () => {
  const data = {
    most_used_content: {
      "Hajar Pookie": {
        most_used_word: ["you", 1076],
        most_used_emoji: ["😂", 4441],
      },
      Oussama: {
        most_used_word: ["and", 727],
        most_used_emoji: ["😂", 686],
      },
    },
    activity_patterns: {
      hourly_activity: {
        "0": 1143,
        "1": 590,
        "2": 77,
        "3": 2,
        "4": 2,
        "6": 5,
        "7": 24,
        "8": 55,
        "9": 204,
        "10": 551,
        "11": 1020,
        "12": 1132,
        "13": 1016,
        "14": 905,
        "15": 871,
        "16": 1070,
        "17": 814,
        "18": 986,
        "19": 1218,
        "20": 1273,
        "21": 1178,
        "22": 1797,
        "23": 2131,
      },
      weekday_activity: {
        Friday: 2086,
        Saturday: 2456,
        Monday: 4189,
        Tuesday: 2463,
        Wednesday: 2311,
        Sunday: 2412,
        Thursday: 2147,
      },
    },
    basic_stats: {
      total_messages: 18064,
      total_days: 432,
      daily_average: 41.81,
      messages_per_sender: { Oussama: 9917, "Hajar Pookie": 8147 },
      media_messages_per_sender: { Oussama: 713, "Hajar Pookie": 929 },
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const hourlyActivityData = {
    labels: Object.keys(data.activity_patterns.hourly_activity),
    datasets: [
      {
        label: "Messages Per Hour",
        data: Object.values(data.activity_patterns.hourly_activity),
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const weekdayActivityData = {
    labels: Object.keys(data.activity_patterns.weekday_activity),
    datasets: [
      {
        label: "Messages Per Day",
        data: Object.values(data.activity_patterns.weekday_activity),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#C9CBCF",
        ],
      },
    ],
  };

  const messageShareData = {
    labels: Object.keys(data.basic_stats.messages_per_sender),
    datasets: [
      {
        label: "Messages Per Sender",
        data: Object.values(data.basic_stats.messages_per_sender),
        backgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const slides = [
    <div key="1">
      <h3>Hourly Activity</h3>
      <Bar data={hourlyActivityData} />
    </div>,
    <div key="2">
      <h3>Weekday Activity</h3>
      <Doughnut data={weekdayActivityData} />
    </div>,
    <div key="3">
      <h3>Message Distribution</h3>
      <Doughnut data={messageShareData} />
    </div>,
    <div key="4">
      <h3>Most Used Words</h3>
      <p>Hajar Pookie: {data.most_used_content["Hajar Pookie"].most_used_word[0]} ({data.most_used_content["Hajar Pookie"].most_used_word[1]} times)</p>
      <p>Oussama: {data.most_used_content["Oussama"].most_used_word[0]} ({data.most_used_content["Oussama"].most_used_word[1]} times)</p>
    </div>,
    <div key="5">
      <h3>Most Used Emojis</h3>
      <p>Hajar Pookie: {data.most_used_content["Hajar Pookie"].most_used_emoji[0]} ({data.most_used_content["Hajar Pookie"].most_used_emoji[1]} times)</p>
      <p>Oussama: {data.most_used_content["Oussama"].most_used_emoji[0]} ({data.most_used_content["Oussama"].most_used_emoji[1]} times)</p>
    </div>,
  ];

  return (
    <div style={{ padding: "20px" }}>
      <h1>WhatsApp Analysis</h1>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} style={styles.slide}>
            {slide}
          </div>
        ))}
      </Slider>
    </div>
  );
};

const styles = {
  slide: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
  },
};

export default WhatsAppAnalysis;
